import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { TON_CONNECT_MANIFECT_URL } from './lib/vars';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <TonConnectUIProvider manifestUrl={TON_CONNECT_MANIFECT_URL}>
        <App />
      </TonConnectUIProvider>
    </BrowserRouter>    
  </React.StrictMode>
);


