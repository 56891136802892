import React from 'react'
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { MainComponent } from '../components/MainComponent/MainComponent';

export function Main() {    
      return (
        <div className='body'>
          <div>            
            <MainComponent />            
        </div>
        </div>
      );
  }