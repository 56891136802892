import React, { useEffect, useState } from 'react'
import { useTonAddress } from '@tonconnect/ui-react'
import { Exchanger } from '../Exchanger/Exchanger';


export function MainComponent() {
  return (
        <div className='main-section'>              
              <Exchanger />
        </div>
      );
  }